import React from "react"
import Label, { FillLabelColor } from "./label_pill"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

type PrincipalArticleCardProps = {
  image: any
  title: string
  description: string
  to: string
  category: string
}

function PrincipalArticleCard(props: PrincipalArticleCardProps) {
  const { i18n, t } = useTranslation()

  var categoryColor: FillLabelColor

  switch (props.category) {
    case "learn":
      categoryColor = FillLabelColor.primary
      break
    case "analysis":
      categoryColor = FillLabelColor.neon
      break
    case "market":
      categoryColor = FillLabelColor.attention
      break
    default:
      categoryColor = FillLabelColor.white
      break
  }

  return (
    <div className="flex mt-7 mb-7 w-full relative overflow-hidden rounded-lg shadow-lg cursor-pointer bg-gradient-to-t from-black to-black-alpha-32 h-96 md:h-105 lg:h-[40rem]">
      <a
        className="flex flex-col grow"
        href={`../../${i18n.language}/article/${props.to}/`}
      >
        <GatsbyImage
          image={getImage(props.image)!}
          alt={props.title}
          className="w-full h-full object-cover absolute mix-blend-overlay"
        />

        <div className="absolute m-auto bottom-2 md:bottom-12 left-0 right-0 px-6 py-4 max-w-xl md:max-w-3/5xl">
          <div className="flex mb-4">
            <Label fillColor={categoryColor}>{t(props.category)}</Label>
          </div>

          <h4 className="mb-3 text-2xl md:text-5xl leading-snug font-poppins font-medium tracking-tight text-white">
            {props.title}
          </h4>
          <p className="text-xl md:text-2xl line-clamp-2 font-inter font-normal hyphens-auto leading-normal text-gray-100">
            {props.description}
          </p>
        </div>
      </a>
    </div>
  )
}

export default PrincipalArticleCard
