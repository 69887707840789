import React, { HTMLProps } from "react"

type TitleTextProps = {
  className: HTMLProps<HTMLElement>["className"]
  children: string
}

function TitleText(props: TitleTextProps) {
  return (
    <h1
      className={`text-5xl font-semibold underline underline-offset-14 ${props.className}`}
    >
      {props.children}
    </h1>
  )
}

export default TitleText
