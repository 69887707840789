import React, { useEffect } from "react"
import ArticlesGridView from "../../components/articles_grid_view"
import TitleText from "../../components/title_text"
import PrincipalArticleCard from "../../components/principal_article_card"
import RoundedButton from "../../components/rounded_button"
import { SEO } from "../../components/seo"
import { GetHomeBlogArticlesPT } from "../../graphql/get_home_blog_articles"
import { useTranslation } from "react-i18next"
import { ArticleCardType } from "../../types/article_card_type"
import Layout from "../../components/blog/blog-layout"
import ImageMetaTag from "../../images/image-meta-tag.webp"

const DGFreshIndexPage = () => {
  const { i18n, t } = useTranslation()
  const dataArticles = GetHomeBlogArticlesPT()

  var mainArticle: ArticleCardType
  var analysisArticles: ArticleCardType[]
  var learnArticles: ArticleCardType[]

  // const dataArticles = convertResponseToArticleType(data)

  if (i18n.language == "pt") {
    mainArticle = dataArticles.mainArticle
    analysisArticles = dataArticles.analysisArticles
    learnArticles = dataArticles.learnArticles
  } else {
    mainArticle = dataArticles.mainArticleEN
    analysisArticles = dataArticles.analysisArticlesEN
    learnArticles = dataArticles.learnArticlesEN
  }

  useEffect(() => {
    if (typeof window !== undefined && typeof document !== undefined) {
      const noscript = document.createElement('noscript');

      noscript.innerHTML = `
        <img src="https://ib.adnxs.com/pixie?pi=7b378119-73d3-4c7a-a8dc-79d1256b713b&e=PageView&script=0" width="1" height="1" style="display:none"/>
      `;

      document.body.insertBefore(noscript, document.body.firstChild);
      
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-P23GV22X63');

        !function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
        pixie('init', '7b378119-73d3-4c7a-a8dc-79d1256b713b');

        pixie('event', 'PageView');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render
  

  return (
    <Layout>
      <main className="flex flex-col items-center mx-auto px-5 xs:mt-28 md:mt-36 max-w-8xl">
        {mainArticle != null && (
          <PrincipalArticleCard
            to={mainArticle.slug}
            image={mainArticle.image}
            title={mainArticle.title}
            description={mainArticle.description}
            category={mainArticle.category}
          />
        )}

        <section className="mt-12">
          <TitleText className="decoration-purple-primary">
            {t("learn")}
          </TitleText>
          <div className="mb-12"></div>

          <ArticlesGridView
            className="grid-cols-1 sm:grid-cols-8 lg:grid-cols-12"
            cardClasses="cols-span-1 sm:col-span-4"
            articles={learnArticles
              .filter(article => article.category == "learn")
              .slice(0, 9)}
          />
        </section>

        <RoundedButton onClick={() => (location.href = "/aprenda/")}>
          {t("seeAll")}
        </RoundedButton>

        <hr className="mt-6 border-lines w-full" />

        <section className="mt-12 mb-6">
          <TitleText className="decoration-green-neon ">
            {t("analysis")}
          </TitleText>
          <div className="mb-12"></div>
          <ArticlesGridView
            className="grid-cols-1 sm:grid-cols-8 lg:grid-cols-12"
            cardClasses="col-span-1 sm:col-span-4 lg:col-span-6"
            articles={analysisArticles
              .filter(article => article.category == "analysis")
              .slice(0, 2)}
          />
        </section>

        <RoundedButton onClick={() => (location.href = "/analises/")}>
          {t("seeAll")}
        </RoundedButton>
      </main>
    </Layout>
  )
}

export const Head = () => {
  let currentDate = new Date().toISOString().split("T")[0]

  return (
    <SEO 
      title="DgFresh"
      pathname="blog"
      description="Negocie criptomoedas com $0 de taxa. Receba pagamentos diários em nosso token exclusivo, o DGTA token. / Comece agora na Digitra.com!"
      thumbnail={ImageMetaTag}
      pageType="CollectionPage"
    />
  )
}

export default DGFreshIndexPage
